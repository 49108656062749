.transactionTable {
    text-align: left;
    margin: 0px;
    padding: 0px;
    border-spacing: 5px;
    border-collapse: collapse;
}

.tableTh {
    padding-right: 5px;
}

.tableTd {
    padding-right: 5px;
}


#SearchContainer {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    margin-top: 6px;
}

.SearchInput {
    max-width: 300px;
    border: solid 2px;
    height: 20px;
}

.buttonBar {
    padding: 6px;
}